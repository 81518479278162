import { Link } from '@smartproxy-web/ui';
import { useBuildContext } from '../../contexts/BuildContext';
import { GatsbyLinkProps, Link as _GatsbyLink } from 'gatsby';
import { sameTabDomains } from './constants';
import { useLinkConfig } from '../../contexts';
import { getFollowStatus } from './useLinkConfig';

export type TGatsbyLinkProps = Omit<
  GatsbyLinkProps<Record<string, unknown>>,
  'ref'
> & {
  isForcedInternalLink?: boolean;
};

export const nextJsPages = [
  '/apps',
  '/blog',
  '/about',
  '/case-studies',
  '/how-it-works',
  '/devs',
  '/legal',
  '/proxy-affiliate-program',
  '/smartproxy-quick-start-guide',
  '/targets',
  '/why-smartproxy',
  '/partners-guide',
  '/glossary',
  '/best',
  '/use-cases',
  '/integrations',
  '/scraping',
  '/what-is-my-ip',
  '/deals/es-proxy-servers',
  '/configuration',
];
export function checkIfItIsNextjsPage(url: string) {
  return url && nextJsPages.some((page) => url.startsWith(page));
}
export function extractPathname(url) {
  try {
    const tempAnchor = document.createElement('a');

    if (url.startsWith('/')) {
      tempAnchor.href = 'http://dummy' + url;
    } else if (url.startsWith('#')) {
      tempAnchor.href = 'http://dummy' + url;
    } else if (!url.includes('://')) {
      tempAnchor.href = 'http://' + url;
    } else {
      tempAnchor.href = url;
    }

    // Return the pathname of the parsed URL
    return tempAnchor.pathname.startsWith('/dummy')
      ? tempAnchor.pathname.substring(6)
      : tempAnchor.pathname;
  } catch (error) {
    return false;
  }
}

export const GatsbyLink = ({
  to,
  children,
  rel,
  ...rest
}: TGatsbyLinkProps) => {
  const { site } = useBuildContext();
  const config = useLinkConfig();
  const followStatus = getFollowStatus(config, to);

  const createLinkPropsFromUrl = (rawUrl: string) => {
    try {
      const url = new URL(rawUrl);

      const isInSameTabDomains = [...sameTabDomains, site].includes(
        url.hostname
      );
      const isSameSite = site === url.hostname;

      const props = {
        ...rest,
        ...(isSameSite && {
          to: url.pathname + url.hash,
          as: _GatsbyLink,
        }),
        ...((!isSameSite || isSameSite) && {
          href: rawUrl,
        }),
        ...(!isInSameTabDomains && { target: '_blank' }),
        rel: rel || followStatus,
      };
      return props;
    } catch (error) {
      return {
        ...rest,
        href: rawUrl,
      };
    }
  };

  const props = createLinkPropsFromUrl(to);
  const pathname = to ? extractPathname(to) : '';
  const isNextJsLink = pathname ? checkIfItIsNextjsPage(pathname) : false;
  if (isNextJsLink && pathname) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.as = undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.to = undefined;
    return (
      <a
        {...props}
        href={pathname}
        onClickCapture={(e) => {
          e.preventDefault();
          window.location.pathname = pathname;
        }}
      >
        {children}
      </a>
    );
  }

  return <Link {...props}>{children}</Link>;
};
